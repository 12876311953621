.my_appointments_container{
    background: #E9EFF4;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.my_appointments_container .student_details {
    background-color:#78AED3 ;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 13px;
}
.my_appointments_container .appointment_details div span:last-child{
    color: #525B88;
    font-size: 12px;
    font-weight: 500;
}
.my_appointments_container .appointment_details div{
    margin-bottom: .3rem;
}
.my_appointments_container .appointment_details span{
    color:#696A6F;
    font-weight: 500;
    font-size: 12px;
}
.my_appointments_container button:first-child{
    background-color: #DB3665;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
}

.my_appointments_container button{
    background-color: #7ECC41;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
    font-size: 12px;
}