.my-student-container{
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.my_student_details_container{
    background: linear-gradient(90deg, #E0EAFC 0%, #CFDEF3 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.my_student_contact_details_container{
   display: flex;
   justify-content: space-between;
   align-items: center;
   box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
border-radius: 0px 0px 10px 10px
   
}

.my_student_call_icon{
    background-color: #E6EFF5;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-student-text{
    font-size: 12px;
    color:#2B2C2F ;
    font-weight: 600;
}