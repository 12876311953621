/* StudentDetails start */

.student-details1 {
    background: linear-gradient(
        88.32deg,
        rgba(195, 86, 115, 0.17) -17.54%,
        rgba(205, 92, 88, 0.17) 65.93%
    );
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.back-icon-header {
    position: fixed;
    z-index: 9999;
    top: 10px;
}

.student-details2 {
    background: linear-gradient(
        92.4deg,
        rgba(107, 144, 207, 0.17) 14.32%,
        rgba(207, 222, 243, 0.17) 89.92%
    );
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.student-details-profile {
    background: url("../Images/allStudentDetailPattern.png"),
        linear-gradient(to bottom right, #c1486f, #ce5f53);
    border-radius: 0px 0px 20px 20px;
    height: 55vw;
}

.student-details-image1 {
    position: absolute;
    right: 2px;
}

.student-details3 {
    background: linear-gradient(
        88.32deg,
        rgba(194, 223, 196, 0.6) 14.32%,
        rgba(222, 235, 221, 0.6) 89.92%
    );
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.student-details4 {
    background: linear-gradient(
        92.4deg,
        rgba(243, 230, 219, 0.6) 14.32%,
        rgba(241, 223, 209, 0.6) 89.92%
    );
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.student-details5 {
    background: linear-gradient(
        92.4deg,
        rgba(251, 231, 125, 0.48) 14.32%,
        rgba(239, 225, 152, 0.48) 89.92%
    );
    border-radius: 10px;
    padding: 10px 10px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    position: relative;
}

.student-details-image2 {
    position: absolute;
    right: 10px;
}

.student-details-image3 {
    position: absolute;
    right: 2px;
}

.student-details-image4 {
    position: absolute;
    right: -13px;
    bottom: -29px;
}

.student-details-header {
    color: #c24a6b;
    font-weight: 600;
}

/* StudentDetails End */
/* splash section css */

.splash-section {
    background: linear-gradient(178.71deg, #c04673 5.47%, #cf6151 100.78%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.splash-section::-webkit-scrollbar {
    display: none;
}

.mt-12vw {
    margin-top: 12vw;
    width: 100%;
    height: 50%;
}

.next-btn {
    width: 114px;
    background-color: #ffffff;
    color: #c04673;
    margin-top: 55px;
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}

.get-started-btn {
    width: 166px;
    background-color: #ffffff;
    color: #c04673;
    margin-top: 55px;
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
}

.scroll {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    width: 24px;
    height: 6px;
    background: #ffffff;
    border-radius: 25px;
    margin-top: 50px;
    margin-right: 4px;
}

.slider1 {
    align-items: center;
    width: 6px;
    height: 6px;
    background: #ffffff;
    border-radius: 25px;
    margin-top: 50px;
    margin-right: 4px;
}

/* splash section css end */
/* footer style */

.footer-link {
    text-decoration: none;
}

.bottom-bar {
    position: fixed;
    bottom: 0px;
    width: 100%;


    height: 12vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 45%);
    background-color: #ffffff;
}

.footer-inactive {
    color: #8f9aa3 !important;
    font-size: large;
}

.footer-active {
    color: #bf4474 !important;
    font-size: large;
}

.fs-7 {
    font-size: 0.8em !important;
}

.home-icon {
    background-color: #bf4474;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 47px;
    width: 47px;
    font-size: 1.5em;
    border-radius: 50%;
}

.touch-ink {
    color: #aaaaaa;
}

.keke-color {
    color: #bf4474;
}

/* .p1 {
    display: flex;
    justify-content: space-evenly;
} */
/* footer style end */
/* review card start */

.review-card {
    background: rgba(185, 228, 255, 0.5);
    mix-blend-mode: darken;
    box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1);
}

.review-card2 {
    background: rgba(210, 141, 183, 0.5);
    mix-blend-mode: darken;
    box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1);
}

.quote-icon {
    height: 17px;
    width: 23.28571319580078px;
    top: 133px;
    left: 44.49px;
}

.face {
    height: 64px;
    width: 64px;
    left: 266px;
    top: 143px;
    border-radius: 80px;
}

/* review end */

/* appointment start */

.appointment-card {
    background: #eee8ff;
    mix-blend-mode: darken;
    box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1);
    color: #9c8ec3;
}

.appointment-card2 {
    background: #e1fbfb;
    mix-blend-mode: darken;
    box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1);
    color: #4da0b3;
}

.msgbtn {
    font-size: 1.5em;
}

.appointment-card-bg {
    background: #d4f5f8;
    mix-blend-mode: darken;
    box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1);
    color: #9c8ec3;
}

.appoinment-btn {
    background-color: #7bbf45;
    color: #ffffff;
    height: 50px;
    /* width: 100%; */
    border-radius: 10px;
    border: none;
    font-weight: 700;
    size: 18px;
}

/* .bg-btn {
    background-color: #ffffff;
} */
/* appointment end */

/* today class start */

.tdcls-card {
    background: #e9eaf4;
    border-radius: 12px;
}

.tdcls-card2 {
    background: #ffeeea;
    border-radius: 12px;
}

.tdcls-circle {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.tdcls-name {
    /* padding-left:100px; */
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.09em;
    text-align: right;
}

.tdcls-content {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.035em;
    text-align: left;
}

.tdcls-time {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.06em;
    text-align: left;
}

/* today class end */
/* parent login start */

.login-form {
    background: linear-gradient(
        145.36deg,
        rgba(244, 244, 244, 0.4) -4.23%,
        rgba(244, 244, 244, 0.2) 102.58%
    );

    /* margin-bottom: 20px; */
    padding: 16px;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    height: 230px;
    width: 90%;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 5%;
}

.login-btn-div {
    margin-top: 41px;
    margin-bottom: 20px;
}

.submit-button {
    background-color: #bf4571;
    padding: 15px;
    border-radius: 12px;
}

.login-button {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(198, 82, 100, 0.948) 102.74%
    );
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 13px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: white;
}

.forget-link {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
}

.login-input {
    background: linear-gradient(
        145.36deg,
        rgba(244, 244, 244, 0.4) -4.23%,
        rgba(244, 244, 244, 0.2) 102.58%
    );
    border-bottom: 0.5px;
    font-size: 14px;
    font-weight: 400;
}

.logo-image {
    display: flex;
    justify-content: center;
    margin-top: 85px;
    /* margin-bottom: 30px */
}

.username-input-login {
    background: transparent;
    border: rgba(255, 255, 255, 0.2);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
    outline: none;
    font-size: 0.89rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.username-input-login:active {
    outline: none;
    background: transparent;
}

.username-input-login::placeholder {
    color: #ffffff33;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

/* parent login end */
/* single teacher page  */

.teacher-image {
    background: url("../Images/singleteacher\(2\).png"),
        linear-gradient(99.94deg, #bf4571 34.93%, #c65163 57.46%);
    border: none;
    border-radius: 0px 0px 30px 30px;
    height: 24vh;
}

.Teacher-details {
    display: flex;
    justify-content: space-between;
    background: rgba(199, 90, 113, 0.07);
    padding: 15px;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    box-shadow: 0 3px 10px #F4F8FF(0 0 0 / 20%);
}

.contact-btn {
    background: linear-gradient(99.94deg, #bf4571 34.93%, #c65163 57.46%);
    color: #ffffff;
    height: 50px;
    /* width: 100%; */
    border-radius: 10px;
    border: none;
    font-weight: 700;
    size: 18px;
}

/* //single teacher page end */
/* single live class start */

.single-live-section {
    height: 90vh;
    background-color: #f5f5f5;
}

.single-live-banner {
    width: 100%;
    height: 280px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.single-live-btn {
    color: #ffffff;
    text-align: center;
    border: none;
    background-color: #8ee1e7;
    width: 272px;
    border-radius: 16px;
    padding: 12px 18px;
    margin-bottom: 15px;
}

.play-btn {
    color: #ffffff;
    border: none;
    background-color: #8ee1e7;
    margin-left: 20px;
    font-weight: 700;
    font-size: 18px;
}

.single-live-details {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    color: #141f1f;
}

.single-live-join-btn {
    border: none;
    background: linear-gradient(96.46deg, #8ee1e7 27.22%, #8ad8de 73.03%);
    color: #fff;
    border-radius: 12px;
    padding: 14px 22px;
    font-size: 18px;
    font-weight: bold;
}

.single-live-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.icon-text {
    margin-left: 5px;
    font-size: 12px;
}

/* single live class end */
/* profile styles start */

.pbg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.profile-info {
    background-image: url("../Images/profilebg.png");
    position: absolute;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 40vh;
    width: 100%;
}

.profileform {
    display: flex;
    justify-content: space-between;
    background: #f4f8ff;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

/* profile styles end */
/* all subjects start */

.subject {
    position: relative;
    padding: 12px;
    border-radius: 7px;
    margin: 30px 0px 18px 0px;
    height: 107px;
}

.sub-image1 {
    position: absolute;
    right: 22px;
    bottom: 20px;
}

.sub-image2 {
    position: relative;
    bottom: 27px;
    left: 3px;
}

.subject-head {
    color: #c24a6b;
    font-weight: 600;
    text-align: center;
}

/* all subjects end */
/* single subject start */

.single-subject {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 12px;
    border-radius: 7px;
    margin: 0 18px 0 18px;
    height: 150px;
}

.subject-image {
    position: absolute;
    right: 16px;
    bottom: 12px;
}

.subject-date {
    border-radius: 7px !important;
    height: 50px;
    width: 88vw;
    border: none !important;
    background: rgba(190, 175, 179, 0.05) !important;
    border-radius: 10px;
}

.subject-download {
    background: #4ca1af;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 700;
}

.subject-download:focus {
    background: #4ca1af;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 700;
}

.subject-download-red {
    background: #dd628c;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 700;
}

.subject-download-red:focus {
    background: #dd628c;
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 700;
}

.sub-container {
    display: flex;
    justify-content: space-between;
}

.sub-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 18px;
}

.sub-img {
    height: 28vw;
    width: 28vw;
    object-fit: cover;
    border-radius: 5px;
}

/* single subject end */
/* student home work */

.backbutton-fixed {
    margin-left: 0px;
    margin-right: 12px;
    padding: 2px;
}

.student-homework {
    background: linear-gradient(
        88.32deg,
        rgba(195, 86, 115, 0.17) -17.54%,
        rgba(205, 92, 88, 0.17) 65.93%
    );
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.align {
    margin-top: 6vw;
    /* padding: 15px; */
    border-radius: 12px;
}

.student-homework-head {
    color: #c24a6b;
    font-weight: 600;
}

/* student home work  */
/* All Online Test start */

.all-online-test {
    /* margin: 22px 15px 15px 15px; */
    display: flex;
    justify-content: space-between;
    /* padding: 10px; */
}

.online-test-name {
    position: absolute;
    width: 153px;
    padding: 6px;
    margin-left: 55px;
}

.joining-btn {
    background-color: #2f80ed;
    border-radius: 10px;
    padding: 5px 12px;
    width: 60px;
}

.question-card {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.single-test-heading {
    background: #a6afea;
    border-radius: 10px;
}

.question-option {
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.02);
    background: rgba(190, 175, 179, 0.05);
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    padding: 16px 24px;
    color: #86878e;
    overflow-wrap: break-word;
}

.question-option-active {
    background: rgba(0, 56, 255, 0.6);
    color: white;
}

/* All Online Test end */
/* single subject notes start*/

.single-subject-note {
    border-radius: 15px;
    height: 80vh;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.note-download {
    position: absolute;
    right: 2px;
    bottom: 2px;
    background: linear-gradient(99.94deg, #7bbf45 34.93%, #7ecb40 57.46%);
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    width: 136px;
    padding: 8px 18px;
}

/* end single subject notes */
/* single homework start */

.single-hw-img {
    background-size: cover;
    padding: 12px;
    height: 150px;
    border-radius: 8px;
}

.single-hw-text {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #524a4d;
}

.single-hw-text2 {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    color: #86878e;
}

.hw-img {
    height: 30vw;
    width: 30vw;
    object-fit: cover;
    border-radius: 5px;
}

.radio-image {
    position: absolute;
    top: 8px;
    right: 9px;
    width: 17px;
    height: 17px;
    border: 2px solid white;
    background: transparent;
}

/* single homework end */

.bottom-10 {
    bottom: 10px;
}

/* tutorial library start*/

.tutorial-card {
    background-color: rgba(190, 175, 179, 0.1);
    border-radius: 10px;
}

.tutorial-heading {
    color: #524a4d;
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
}

.tutorial-details {
    color: #a09a9c;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}
.tutorial-details-btn {
    color: #c24a6b;
    border-radius: 10px;
    border: 1px solid #c24a6b;
    text-align: center;
    font-size: 8px;
    font-weight: 500;
}
/* tutorial library end*/
/*post on wall start*/

.post-on-wall-file-select {
    background: rgba(190, 175, 179, 0.05);
    border-radius: 10px;
}

.post-on-wall-card {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.post-on-wall-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4a4245;
}

/*post on wall end*/
/* activity start */

.activity-card {
    background: #eeeff7;
    border-radius: 10px;
}

.activity-card2 {
    background: #f1faf3;
    border-radius: 10px;
}

.activity-date {
    font-size: 12px;
    font-weight: 500;
}

.activity-content {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
}

.activity-head {
    color: #c24a6b;
    font-weight: 600;
}

/* activity end */
/* edit parent starts */

.edit-parent-bg {
    background: url("../Images/allStudentDetailPattern.png"), #db3665;
    /* position: absolute; */
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 30vh;
    width: 100%;
}

.edit-parent-pic {
    top: 40vw;
    background-color: #fff;
    height: 20vh;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.profile-img-style {
    background-color: #ffffff;
    height: 27vw;
    width: 27vw;
    object-fit: cover;
    border-radius: 50%;
}

.profile-edit-form {
    justify-content: space-between;
    background: rgba(190, 175, 179, 0.05);
    padding: 16px 12px;
    border-radius: 10px;

    /* border-color: #BEAFB30D; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 5%) inset;
}

profile-edit-form:focus {
    border: none;
    outline: none;
    justify-content: space-between;
    background: rgba(190, 175, 179, 0.05);
    padding: 16px 12px;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 5%) inset;
}

/* .add-subject-btn{
        background-color: #DB3665;
        right: 0px;
        border: none;
        outline: none;
        width: 40vw;
        border-radius: 10px;
        font-weight: 400;
    } */

.optional-badge {
    position: absolute;
    right: 10px;
    color: #c44f72;
    background-color: #ffffff;
    padding: 4px 7px;
}

.edit-profile-btn {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(198, 82, 100, 0.948) 102.74%
    );
    border: 1px solid #bf4672;
    color: #ffffff;
    height: 50px;
    /* width: 100%; */
    border-radius: 10px;
    border: none;
    font-weight: 700;
    font-size: 18px;
}

.input-box-edit {
    background: transparent;
    width: 100%;
}

.input-box-edit::placeholder {
    color: #86878e;
}

/* edit parent ends */
/*Lottie starts*/

.overlay-loading {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999999;
}

.ongoing-spin {
    background-color: #ffffff;
}

/*Lottie ends*/
/* Events start*/

.events-banner {
    width: 100%;
    height: 140px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 20px;
}

.events-details {
    width: 90%;
    background-color: rgba(255, 252, 237, 0.37);
    border-radius: 12px;
}

.events-image {
    object-fit: cover;
    background-repeat: no-repeat;
    width: 100%;
}

/* events end */
/* my cart starts */

.cart-obj {
    display: flex;
    justify-content: space-between;
    margin: 16px 16px 16px 16px;
    padding: 6px;
    background: #ffffff;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.cart-price {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #222222;
    padding: 6px;
}

.increment {
    border: none;
    width: 4vw;
    outline: none;
}

.increment::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
}

.cart-total-amt {
    position: absolute;
    top: -21px;
    left: 2px;
    font-weight: 500;
    font-size: 14px;
    color: #9b9b9b;
}

.cart-total-rs {
    position: absolute;
    top: -25px;
    right: -4px;
    font-weight: 700;
    font-size: 25px;
    line-height: 22px;
    text-align: right;
    color: #222222;
}

.check-out-btn {
    background: #0f172a;
    box-shadow: 0px 4px 8px rgba(211, 38, 38, 0.05);
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding: 12px 18px;
    /* display: flex;
        align-items: center; */
    text-align: center;
    color: #ffffff;
}

/* my cart ends */
/* attendence start */

.attendence-input {
    background: #f5f5f5 !important;
    border-radius: 10px !important;
    height: 50px;
    width: 100%;
    border: none !important;
    /* fees input font */
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

.attendence-input::placeholder {
    color: #86878e;
    font-weight: 400;
    font-size: 12px;
}

.attendence-input:focus {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.attendence-input:active {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.attendence-btn {
    background: linear-gradient(99.94deg, #7bbf45 34.93%, #7ecb40 57.46%);
    border-radius: 10px;
    border: none;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
}

/* make-appointment starts */
.appointment-textarea {
    width: 100%;
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    border: none;
    background: #f5f5f5;
}

.appointment-textarea:focus {
    outline: none;
}

.appointment-date {
    width: 100%;
    border: none !important;
    box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.02);
    height: 50px;
    background: #f5f5f5;
}

.appointment-time {
    width: 100%;
    border: none !important;
    height: 50px;
}

.appointment-btn {
    background: linear-gradient(99.94deg, #7bbf45 34.93%, #7ecb40 57.46%);
    border-radius: 10px;
    color: #ffffff;
    border: none;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    margin-top: 10px;
    padding: 8px 18px;
}

.attendence-btn-div {
    display: grid;
    position: fixed;
    bottom: 18px;
}

.ant-picker-input > input::placeholder {
    color: #ffffff;
    padding: 10px;
}

/* make-appointment end */

.address-input-field {
    border-radius: 10px;
    border: none;
}

.address-btn {
    margin-bottom: 20px;
}

.save-btn {
    background: #0f172a;
    color: #86878e;
}

/* make-appointment ends */

/* parent-address starts here */
.address-main-div {
    background-color: #f9f9f9;
    height: 100vh;
    font-family: "Ubuntu";
}

.address-input-field {
    border-radius: 10px;
    border: none;
}

.address-btn {
    margin-bottom: 20px;
}

.save-btn {
    background: #0f172a;
    border-radius: 10px;
    border: none;
    font-weight: 650;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 15px;
    margin-right: 1em;
}

.save-btn-div {
    position: fixed;
    bottom: 5%;
    font-family: "Ubuntu";
}

/* parent-address-ends here */

/* store css starts */
.store-search-bar {
    background: #f3f3f4;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 1em;
}

.store-cart-box {
    background: #f3f3f4;
    border-radius: 10px;
    width: 46px;
    height: 47px;
}

.store-recommend-btn {
    background: #0f172a;
    border-radius: 10px;
    color: white;
    border: none;
}

.main-menu {
    display: grid;
    overflow-x: scroll;
    background: none !important;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.store-recommend-btn-2 {
    background: #ffffff;
    box-shadow: 0px -1px 5px rgba(210, 210, 210, 0.25);
    border-radius: 10px;
    border: none;
}

/* store css ends */
/* parent-address starts here */
.address-main-div {
    background-color: #f9f9f9;
    height: 100vh;
    font-family: "Ubuntu";
}

.store-items-content {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.store-items-price {
    font-weight: 500;
    font-size: 12px;
    color: #f45858;
}

.store-percentage {
    background: #f45858;
    border-radius: 6px;
    color: white;
    font-weight: 700;
    font-size: 8px;
    padding: 2px;
}

.store-header {
    font-weight: 500;
    font-size: 16px;
    color: #c24a6b;
}

/* store css ends */

/* single-store css starts */
.icon-single-store {
    position: absolute;
    background: rgba(194, 74, 107, 0.75);
    border-radius: 21px;
    top: 5%;
    right: 9%;
    padding: 1vh;
}

.slick-slide .image-slider {
    margin: auto;
    width: 96%;
    height: 50%;
}

.single-store-button-save {
    background: #f45858;
    border-radius: 6px;
    width: fit-content;
    margin-left: 2vh;
}

.btn-single-store {
    border: none;
    background: #0f172a;
}

.btn-single-store {
    border: none;
    background: #0f172a;
    border-radius: 10px;
    width: 98vw;
}

.btn-single-store {
    border: none;
    background: #0f172a;
    border-radius: 10px;
    width: 90vw;
    padding: 1em;
    margin-top: 3em;
    margin-left: 1em;
}

.btn-single-store-quantity {
    border: none;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 60vw;
    padding: 1em;
    color: #000;
}

.btn-single-store-plus {
    border: none;
    background: #0f172a;
    border-radius: 10px;
    height: 3em;
    width: 3em;
    padding: 1em;
    color: #fff;
    margin-right: 20px;
}

.btn-cart-minus {
    border: none;
    background: #0f172a;
    border-radius: 7px;
    height: 20px;
    width: 20px;
    /* padding: 1em; */
    color: #fff;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-cart-plus {
    border: none;
    background: #0f172a;
    border-radius: 7px;
    height: 20px;
    width: 20px;
    /* padding: 1em; */
    color: #fff;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-quantity-text {
    font-size: 13px;
    font-weight: 600;
}

.btn-single-store-minus {
    border: none;
    background: #0f172a;
    border-radius: 10px;
    height: 3em;
    width: 3em;
    padding: 1em;
    color: #fff;
    margin-left: 20px;
}

.cart-quantity {
    background-color: #0f172a;
    color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    text-align: center;
    right: 0;
    top: -5px;
    font-size: 13px;
}

/* single-store css ends */
.input-content {
    color: red;
    outline: none;
    border: none;
    width: 100%;
    border-radius: 10px;
}

.input-content::placeholder {
    color: #9b9b9b;
    font-family: "Ubuntu";
}

/* chat--starts here */
.chat-div {
    background: rgba(255, 255, 255, 0.95);
    margin-top: 3vh;
}

.chat-people {
    background: #f9f9fa;
    color: #9b9b9b;
    font-family: "Ubuntu";
}

/* chat--ends here */

/*exam-schedule-start*/
.exam-schedule-btn-div {
    background: #ffffff;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0.5em;
}

.exam-schedule-cls-tst {
    background: linear-gradient(95.13deg, #c34a6d 21.02%, #cb595b 66.71%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: none;
    color: white;
    padding: 0.4em;
}

.exam-schedule-time {
    font-weight: 500;
    font-size: 14px;
    color: #bcc1cd;
}

.exam-schedule-card-1 {
    background: #4dc591;
    border-radius: 16px;
}

.exam-schedule-card-2 {
    background: linear-gradient(115.42deg, #78aed3 24.78%, #6ea7ce 76.41%);
    border-radius: 16px;
}

.exam-schedule-card-3 {
    background: linear-gradient(102.77deg, #c24a6b 11.9%, #bc3d60 85.65%);
    border-radius: 16px;
}

.exam-schedule-card-4 {
    background: linear-gradient(104.33deg, #b5ceff 23.56%, #a9c5fd 85.98%);
    border-radius: 16px;
}

/*exam-schedule-end*/

/* parent-address-ends here */
/* parent-address-ends here */

/* timetable starts here  */
.timetable-head {
    color: #c24a6b;
    font-weight: 500;
}

.timetable-btn {
    margin-left: 297px;
    margin-top: -39px;
}

.timetable-days {
    background: rgba(190, 175, 179, 0.05);
    border-radius: 10px;
    margin: 1em;
}

/* timetable ends here  */

/* setting starts here  */
.setting-bg {
    background-image: url("../Images/bg setting.png");
    padding: 2em;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.settings-curve {
    margin-top: 20%;
    /* padding-top: 50px; */
    background-color: #f6f6f6;
    height: 85vh;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.settings-panel {
    background-color: rgb(231, 231, 226);
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 25px;
}

.gsettings-bg {
    background-image: url("../Images/bg\ setting.png");
}

.icon-symbol {
    background-color: #5ccbf1;
    border-radius: 8px;
    color: #fff;
    padding-top: 0.3em;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.3em;
}

/* setting ends here  */

/* timetable ends here  */

/* home */

.liveclass {
    display: grid !important;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.div-class {
    background: #e39a85;
    border-radius: 8px;
    align-self: flex-end;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}

.viewteacher {
    display: grid !important;
}

.teacher-div {
    /* width: 75vw;
    height: 62vw; */
    object-fit: cover;
    border-radius: 15px;
    position: relative;
}

.wrapper {
    /* width: 100%; */
    height: 35%;
    bottom: 0px;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.wrapper1 {
    width: 100%;
    height: 75%;
    bottom: 0px;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.catagorie-div {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.teacher-details {
    background-color: #eedab6;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
    font-size: 11px;
    font-weight: 400;
}

.message-details {
    background-color: #eedab6;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
    font-size: 11px;
    font-weight: 500;
}

.cata-name {
    font-size: 12px;
    font-weight: 400;
}

.appointment {
    display: grid !important;
}

.appointment {
    display: grid !important;
}

.appointment1-card {
    background: #eee8ff;
    mix-blend-mode: darken;
    /* box-shadow: -4px 4px 5px rgba(0, 0, 0, 0.1); */
    color: #9c8ec3;
    border-radius: 10px;
    width: 80vw;
}

.tutorial-panel {
    padding-bottom: 40%;
}

/* home end */

/* change-password starts here  */

.back-occasional {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.password-div {
    background: #fff;
    border-radius: 40px 40px 0px 0px;
    position: absolute;

    top: 140px;
    width: 100%;
}

.password-bg {
    background-image: url("../Images/bg setting.png");
    padding: 7em;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: -6em;
}

.password-sub {
    margin: 2em;
}

.password-head {
    color: #fff;
    margin-left: 5em;
    margin-top: 5vh;
    padding-bottom: 1.5em;
}

.password-input {
    background: rgba(190, 175, 179, 0.05);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-top: 1vh;
}

.password-input1 {
    width: 100%;
    border: none;
    background: #f7f7f7;
    outline: none;
}

.btn-password {
    background-color: #bf4672;
    border-radius: 10px;
    border: none;
}

/* password ends here  */

/* about-us */

/* about-us */

/* Grade Card starts here */

.name-term {
    font-weight: 500;
    font-size: 16px;
    color: #2b2c2f;
}

.panel-board {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.panel-head {
    background: rgba(170, 201, 221, 0.3);
    font-size: 12px;
    height: 50px;
}

.panel-head-1 {
    border-radius: 12px 0px 0px 12px;
    font-weight: 500;
}

.panel-head-2 {
    font-weight: 500;
}

.panel-head-3 {
    border-radius: 0px 12px 12px 0px;
    font-weight: 500;
}

.panel-content {
    font-weight: 400;
    font-size: 12px;
    color: #86878e;
}

.panel-top {
    font-weight: 400;
    font-size: 12px;
    color: #aac9dd;
    border-radius: 8px;
    outline: 1px solid #aac9dd;
}

/* Grade Card ends here */

/* Help starts here */

.help-bg {
    background-image: url("../Images/bg\ setting.png");
    height: 18em;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.help-div {
    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    position: absolute;
    top: 153px;
    width: 100%;
}

.help-sub {
    font-weight: 400;
    font-size: 14px;
    color: #2f2f2f;
    margin: 2em;
}

.input-div {
    background: rgba(190, 175, 179, 0.05);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-top: 1vh;
    border: 1px solid #ffffff !important;
    resize: none;
    height: 45vw;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

.form-control:focus {
    background: rgba(190, 175, 179, 0.05);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-top: 1vh;
    border: 1px solid #ffffff !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-div::foucs {
    outline: none;
}

.help-btn {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(198, 82, 100, 0.948) 102.74%
    );
    border-radius: 10px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
    padding: 12px;
}

.help-btn-div {
    display: grid;
    position: fixed;
    bottom: 6.53%;
}

/* Help ends here */

/* Student Info starts here */

.student-info-div {
    display: flex;
    justify-content: space-between;
    background: rgba(199, 90, 113, 0.07);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 11px 10px -15px rgb(128, 125, 125);
}

.student-roles {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

.student-details-row {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #2b2c2f;
}

.css-1okebmr-indicatorSeparator {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 0px !important;
    box-sizing: border-box;
}

.homework-input {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
}

.image-input {
    background: #fff;
    padding: 3px;
    border-radius: 10px;
    font-weight: 500;
}

.input-homework {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    border: none !important;
    outline: none !important;
}

.go-back-button {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(198, 82, 100, 0.948) 102.74%
    );
    border: none;
    border-radius: 12px;
}

.pesudo-id {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
}

.pesudo-id::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.wrapper-teacher {
    /* width: 100%; */
    height: 40%;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.badge-teacher {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.class-teacher {
    background: #ffe0e6;
    border-radius: 50px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    box-shadow: 0 3px 10px #F4F8FF(0 0 0 / 45%);
}

/* Student Info ends here */

/* attendance list */
.dsbar {
    background-color: #d9d9d9;
    border-radius: 10px;
}

.present {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 18px 0px #dddddd;
    width: 100%;
}

.absent {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 18px 0px #dddddd;
    width: 100%;
}

.attendence-dwld-btn {
    background-color: #7bbf45;
    border-radius: 10px;
    font-weight: 700;
    color: #fff;
    outline: none;
    border: none;
}

.attendence-dwld-btn:focus {
    background-color: #7bbf45 !important;
    border-radius: 10px;
    font-weight: 700;
    color: #fff;
    outline: none !important;
    border: none !important;
}

.download-btn-bg {
    position: fixed;
    bottom: 0px;
    background-color: #fff;
}

.present-col {
    background-color: #7bbf45;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}

.absent-col {
    background-color: #c24a4a;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}

.half-col {
    background-color: #51bbd2;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
}

/* attendance list */

/* side bar drawer */
.nav-tab-icon {
    padding: 12px 12px;
    background-color: #fff;

    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);

    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
}

.home-login-btn {
    background: linear-gradient(99.94deg, #bf4571 34.93%, #c65163 57.46%);
    color: #ffffff;
    border-radius: 8px;
    font-weight: 700;
}

/* side bar drawer */

/* parent app css end */

/* <-------------------------------------------------------------------------------------------> */

/* teacher app css start */
.home-card {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(198, 82, 100, 0.948) 102.74%
    );
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    box-shadow: none;
}

.teacher-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 7px;
    height: 107px;
}

.teacher-sub-image {
    position: relative;

    bottom: -8px;
    left: 0px;
}

/* welcomme screen start */
.welcome-logo {
    position: fixed;
    top: 5%;
    left: 10%;
    width: 82%;
    height: 38%;
}

.student-apple-img {
    position: relative;
    width: 100%;
    height: 14%;
    -webkit-animation: floatBubble 2s forwards normal ease-in-out;
    animation: floatBubble 2s forwards normal ease-in-out;
}

@-webkit-keyframes floatBubble {
    0% {
        top: 100%;
    }

    100% {
        top: 70%;
    }
}

@keyframes floatBubble {
    0% {
        top: 100%;
    }

    100% {
        top: 70%;
    }
}

.teacher-style-btn {
    background: linear-gradient(
        91.73deg,
        #bf4672 3.55%,
        rgba(226, 105, 123, 0.948) 102.74%
    );
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    box-shadow: none;
    font-weight: 600;
    color: #fff;
}

.textarea-box-meeting {
    background: #f3f2f2;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-top: 1vh;
    border: none !important;
    resize: none;
    height: 25vw;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

.textarea-box-meeting:focus {
    background: #f3f2f2;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-top: 1vh;
    border: none !important;
    resize: none;
    height: 25vw;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

/* welcomme screen end */

/* footer custom style start */
#menu {
    width: 150px;
    height: 150px;
    position: absolute;
    left: 53%;
    top: 114%;
    margin: -67px 0 0 -71px;
    list-style: none;
    font-size: 200%;
}

.menu-button {
    opacity: 0;
    z-index: -1;
}

.menu-button {
    background-color: #bf4474;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 45px;
    width: 45px;
    font-size: 1.5em;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    background: #424242;
    background-size: 100%;
    overflow: hidden;
    text-decoration: none;
}

/* #menu:not(:target)>a:first-of-type,
#menu:target>a:last-of-type {
    opacity: 1;
    z-index: 1;
}

#menu:not(:target)>.icon-plus:before,
#menu:target>.icon-minus:before {
    opacity: 1;
} */

.menu-item {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 55%;
    line-height: 5px;
    top: 50%;
    margin: -92px 0 0 -46px;
    border-radius: 50%;
    transform: translate(0px, 0px);
    transition: transform 500ms;
    z-index: -2;
    transition: 0.5s;
}

.menu-item:hover {
    opacity: 0.5;
    box-shadow: 0 5px 10px black;
}

.menu-item a {
    color: #fff;
    position: relative;
    top: 30%;
    left: 0;
    text-decoration: none;
}

#menu:target > .menu-item:nth-child(6) {
    transform: rotate(25deg) translateY(-103px) rotate(168deg);
    transition-delay: 0s;
    top: 68%;
}

#menu:target > .menu-item:nth-child(5) {
    transform: rotate(12deg) translateY(-167px) rotate(-10deg);
    transition-delay: 0.1s;
    top: 68%;
}

#menu:target > .menu-item:nth-child(3) {
    transform: rotate(-10deg) translateY(-167px) rotate(10deg);
    transition-delay: 0.2s;
    top: 68%;
}

#menu:target > .menu-item:nth-child(4) {
    transform: rotate(-30deg) translateY(-125px) rotate(30deg);
    transition-delay: 0.3s;
    top: 68%;
}

.content {
    position: absolute;
    text-align: center;
    margin: -10px 0 0 -30px;
    top: 70%;
    left: 50%;
    font-size: 20px;
}

.teacher-date-class {
    font-weight: 600;
    font-size: 16px;
}

/* footer custom style end */
/* teacher timetable  */
.time-table-div {
    background: rgba(190, 175, 179, 0.05);
    border-radius: 10px;
    color: #524a4d;
    font-weight: 500;
    font-size: 15px;
}

/* teacher timetable end */

/* teacher homework start */

.teacher-homework-input {
    background: #f5f5f5;
    padding: 3px;
    border-radius: 10px;
}

.teacher-homework-input:focus {
    outline: none;
}

/* teacher homework end */
/* upload answer sheet */
.upload-button {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2b2c2f;
}

/* upload answer sheet end */

/* teacher online test start */
.single-question-input {
    border: none;
    outline: none;
}

.single-question-input:focus {
    outline: none;
}

/* teacher online test end */

/* review */
.review-container {
    background: rgba(222, 226, 252, 0.5);
    border-radius: 10px;
}

.review-head {
    background: #b6bef2;
    border-radius: 10px;
}

/* review end */
/* leave-history */
.leave-history-container {
    background: rgba(170, 201, 221, 0.3);
    border-radius: 10px;
}

.leave-history-head {
    background: #aac9dd;
    border-radius: 10px;
}

/* leave-history end */
/* teacher live class modal */
.modal-yes-btn {
    background: #7ecb40;
    border-radius: 10px;
    color: #fff;
    border: none !important;
    font-weight: 500;
    font-size: 18px;
}

.modal-no-btn {
    background: #db3665;
    border-radius: 10px;
    color: #fff;
    border: none !important;
    font-weight: 500;
    font-size: 18px;
}
/* teacher live class modal end*/
/* teacher app css end */

/* single chat */

.name-bar {
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.card-profile {
    box-shadow: 0px 4px 14px rgba(90, 108, 234, 0.1);
    width: 22vw;
    height: 22vw;
    border-radius: 21%;
    border: 3px solid #bf4474;
    justify-content: center;
    align-items: center;
}

.indicate-dot {
    top: 28px;
    left: 28px;
}

.message-bar {
    background-color: #f5f5f5;
    border-radius: 15px 15px 15px 5px;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
}

.mobile-typeBar {
    border-radius: 12px;
    height: 50px;
    box-shadow: inset 0px 4px 45px rgba(0, 0, 0, 0.08);
}

.chat-bar {
    border: none;
    background: none;
}

.chat-bar::placeholder {
    color: #aca8a5;
    font-size: 14px;
}

.chat-bar:focus {
    box-shadow: none;
    background-color: none;
    outline: none;
}

/* single chat */
/* school media */
.school-media-input {
    background: rgba(190, 175, 179, 0.05) !important;
    border-radius: 10px !important;
    height: 50px;
    width: 100%;
    border: none !important;
    /* fees input font */
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #86878e;
}

.school-media-input::placeholder {
    color: #86878e;
    font-weight: 400;
    font-size: 14px;
}

.school-media-input:focus {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.school-media-input:active {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.media-sheet-btn {
    border-radius: 10px;
    background: #fff;
    border: 1px solid rgb(153, 148, 148);
}

/* school media end */

/* attendence button*/

.ant-radio-group {
    background: none !important;
}

.ant-radio-button-wrapper {
    padding: 5px 2px !important;
    border-radius: 10px !important;
    height: 45px !important;
}

.ant-radio-button-wrapper:hover {
    color: #555 !important;
}

.ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
    )::before {
    background-color: none !important;
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-right-color: none !important;
    color: #fff !important;
    background: #45c671 !important;
    border-color: #45c671 !important;
    padding: 5px 2px !important;
}
