.leave_application_container{
    background: rgba(222, 226, 252, 0.5);
    border-radius: 10px;
}

.leave_application_container .student_details {
    background-color:#B6BEF2 ;
    padding: 8px;
    border-radius: 10px;
    font-size: 12px;
}
.leave_application_container .leave_details div{
    margin-bottom: .3rem;
}
.leave_application_container .leave_details span{
    color:#696A6F;
    font-weight: 500;
    font-size: 12px;
}

.leave_application_container button:first-child{
    background-color: #DB3665;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
}

.leave_application_container button{
    background-color: #7ECC41;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
    font-size: 12px;
}