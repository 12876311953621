.b-r-10 {
    border-radius: 10px;
}

.b-r-15 {
    border-radius: 15px;
}
.fw-500 {
    font-weight: 500;
}
.mb-7 {
    margin-bottom: 6rem;
}
.mr-8 {
    margin-right: 18px;
}
.pb-7 {
    padding-bottom: 6rem;
}
