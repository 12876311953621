.medical_container {
    background: #EDF2EF;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.medical_container .student_details {
    background-color: #A9C7AC;
    padding: 8px 12px;
    border-radius: 10px;
    font-size: 13px;
}
.medical_container .medical_details div span:last-child {
    color: #696A6F;
    font-size: 12px;
    font-weight: 500;
}
.medical_container .medical_details div {
    margin-bottom: 0.3rem;
}
.medical_container .medical_details span {
    color: #696a6f;
    font-weight: 500;
    font-size: 12px;
}
.medical_container button:first-child {
    background-color: #db3665;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
}

.medical_container button {
    background-color: #7ecc41;
    border: none;
    border-radius: 10px;
    width: 35%;
    padding: 5px;
    font-size: 12px;
}
.medical-search-bar {
    background: #ffffff;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 4px 16px;
}
.medical-search-bar input{
    border: none;
    height: 32px;
}
input:focus{
    outline:  none;

}
